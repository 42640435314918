<template lang="pug">
  div.contact
    div.container.blog__wrap
      h1 {{ $t('blog') }}
      div.pa-3.header-wrap
        .contact__form.pa-0
          h4.pa-5.pb-0 {{ $t('blogs.title') }}
          p.px-5.pb-0.mb-5 {{ $t('blogs.description') }}
          div.d-flex.flex-row.flex-wrap.pb-5.px-3
            v-chip(v-for="tag in tags" :key="tag.id" class="blog__tags ma-2"
              :close="routerTags.includes(tag.tag)"
              @click="addTag(tag.tag)"
              @click:close="removeTag(tag.tag)"
            )
              | {{ tag.tag }}

      div.blog__row
        div(v-if="!blogs.length").px-5.py-10.d-flex.flex-row.justify-center.align-center.empty
          h2 {{ $t('notFoundBlogs') }}
        div(
          v-for="blog in blogs"
          :key="blog.id"
        ).blog__list.blog__main.ma-3.container-sm
          h3.mt-0 {{ blog.title }}
          div(
            :style="`background-image: url(${ blog.imgTitle || ogImage }), url(${ ogImage })`"
          ).blog__preview
          //img(
          //  v-if="blog.imgTitle"
          //  :src="blog.imgTitle"
          //  :alt="blog.title"
          //)
          div.blog__button-row
            router-link.blog__button(:to="{ name: 'BlogInfo', params: { id: blog.slug } }") {{ $t('readMore') }}
            span {{ blog.publishedAtStr }}
      div(v-if="meta.next || meta.previous").blog__pagination
        div(@click="setData(meta.page - 1)").next {{ $t('prev') }}
        div(@click="setData(meta.page + (meta.next && 1))").next {{ $t('next') }}
</template>

<script>
import api from '@/plugins/api'

export default {
  name: 'PageBlog',
  metaInfo () {
    return this.$metaDefault()
  },
  data () {
    return {
      ogImage: require('@/assets/images/ogImage.png'),
      blogs: [],
      tags: [],
      meta: {},
      baseUrl: 'api/v1/articles/',
      filter: {
        language: this.$route.query.language || this.$i18n.locale.toUpperCase(),
        page: 1,
        pageSize: 15,
        filter_tags: this.$route.query.filter_tags || ''
      }
    }
  },
  computed: {
    routerTags () {
      return this.$route.query.filter_tags?.split(',') || []
    }
  },
  mounted () {
    this.setData()
    this.setTags()
  },
  methods: {
    async setTags () {
      const data = await api.get(`${ this.baseUrl }tags/`)
      if (data.code !== 200) return
      this.tags = data.data.results
    },
    addTag (tag) {
      if (this.routerTags.includes(tag)) return
      this.$router.push({
        query: {
          ...this.$route.query,
          filter_tags: [...this.routerTags, tag].join(',')
        }
      })
      this.setData(1)
    },
    removeTag (tag) {
      if (!this.routerTags.includes(tag)) return
      this.$router.push({
        query: {
          ...this.$route.query,
          filter_tags: this.routerTags.filter(item => item !== tag).join(',')
        }
      })
      this.setData(1)
    },
    async setData (page = 1) {
      if (!page) return
      const locale = this.$i18n.locale
      const language = locale.toUpperCase()
      const query = Object.keys(this.$route.query).map(item => `${ item }=${ this.$route.query[item] }`).join('&')
      const data = await api.get(`${ this.baseUrl }article/?language=${ language }&page=${ page }&page_size=${ this.pageSize }&${ query }`)

      // this.meta = data.meta
      if (data.code !== 200) {
        this.$router.push({ name: 'not-found' })
        return
      }
      const months = this.$t('months')
      const { results, count, next, previous } = data.data

      this.meta = { count, next, previous, page }
      this.blogs = results.map(item => {
        let publishedAtStr = ''
        if (item.published_at) {
          const date = new Date(item.published_at)
          const year = date.getFullYear()
          const month = months[date.getMonth()]
          const day = date.getDate()
          publishedAtStr = `${ day } ${ month } ${ year }`
        }
        return {
          publishedAtStr,
          imgTitle: item.files.find(img => img.is_title)?.file,
          ...item
        }
      })
      // this.blogs = data.map(item => {

      //   // const published_at_str = `${ day } ${ month } ${ year }`
      //   return {
      //     ...item,
      //     // published_at_str,
      //     feature_image_alt: item.feature_image_alt || 'EasyPON blog'
      //   }
      // })
    }
  }
}
</script>
<style lang="sass">
.empty
  height: 100%
  width: 100%
.header-wrap
  width: 100%
.contact__form
  width: 100% !important
  max-width: 100% !important
.select-container
  width: 250px
</style>
